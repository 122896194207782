<template>
  <div class="app-container">
    <el-row class="margin-bottom-20">
      <el-col :lg="18" :md="16" :sm="16" :xs="12">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="# Orden, ID Shopify, ID Cliente, Correo cliente, Nombre Cliente" v-model="filterForm.query" v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col style="display: flex; justify-content: flex-start;" :md="4" :sm="8" :xs="10">
        <el-button
          @click.stop.prevent="handleSearchFilter"
          class="margin-left-10"
          size="mini"
          type="primary"
          icon="el-icon-search"
        >Buscar</el-button>
        <el-button
          @click.stop.prevent="handleClean"
          size="mini"
          class="margin-left-10"
          type="warning"
          icon="el-icon-delete"
        >Limpiar Filtros</el-button>
      </el-col>
      <!-- <el-col :lg="2" :xs="8" :offset="0">
        <el-button
          @click.stop.prevent="goChart"
          size="mini"
          type="success"
          icon="el-icon-data-line"
        >Grafica</el-button>
      </el-col> -->
      <!-- <el-col :lg="2" :xs="8" :offset="0">
        <el-button
          @click.stop.prevent="handleExport"
          size="mini"
          class="margin-left-10"
          type="success"
          icon="el-icon-bottom"
        >Exportar</el-button>
      </el-col> -->
    </el-row>
    <el-row justify="center">
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="12" :xs="12">
          <el-select v-model="filterForm.filterLocation" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Sucursal">
            <el-option
              v-for="itemL in locations"
              :key="itemL.Location"
              :label="itemL.Sucursal"
              :value="itemL.Location">
            </el-option>
          </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="12" :xs="12">
          <el-select v-model="filterForm.filterStatus" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Estatus">
            <el-option
              v-for="itemS in status"
              :key="itemS.status"
              :label="itemS.status"
              :value="itemS.status">
            </el-option>
          </el-select>
      </el-col>
      <el-col class="grid-content" align="center" :lg="8" :md="12" :sm="12" :xs="24">
        <span class=" margin-filter">Registros por fecha Shopify</span>
          <el-date-picker
            v-model="filterDateRegisterSHRange"
            v-on:change="handleFilterDateRegisterSH()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
      </el-col>
      <el-col class="grid-content" align="center" :lg="8" :md="10" :sm="12" :xs="24">
          <span class=" margin-filter">Registros por fecha Manager</span>
          <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
      </el-col>
      <el-col class="grid-content" align="center" :lg="24" :md="14" :sm="24" :xs="24" style="display: flex; justify-content: flex-end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
      </el-col>
    </el-row>
    <el-table
      size="mini"
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      border
      height="600"
      fit
      highlight-current-row
      style="width: 100%">
      <el-table-column label="Estatus"  width="120" align="center" >
        <template slot-scope="scope">
          <div v-if="scope.row.status == 'Error'">
            <el-tag size="mini" type="danger">{{ scope.row.status }}</el-tag>
          </div>
          <div v-else-if="scope.row.status == 'Invoiced'">
            <el-tag size="mini" type="success">{{ scope.row.status }}</el-tag>
          </div>
          <div v-else-if="scope.row.status == 'Sent to invoicing'">
            <el-tag size="mini" type="warning">{{ scope.row.status }}</el-tag>
          </div>
          <div v-else>
            <el-tag size="mini">{{ scope.row.status }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Folio Fiscal" width="100" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.folio_fiscal">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">{{scope.row.folio_fiscal}}</div>
              <span>{{ scope.row.folio_fiscal.toString().substr(-4) }}</span>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Folio" width="100" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.folio }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Productos" type="expand" width="110">
        <template slot-scope="scope">
          <!-- Tabla productos -->
          <el-table
            :data="scope.row.line_items"
            size="mini"
            border
            highlight-current-row>
            <el-table-column label="ID" width="110">
              <template slot-scope="data">
                <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">{{data.row.id}}</div>
                <span>{{ data.row.id.toString().substr(-4) }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="ID Producto" width="110">
              <template slot-scope="data">
                <div v-if="data.row.product_id != null">
                  <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">{{data.row.product_id}}</div>
                  <span>{{ data.row.product_id.toString().substr(-4) }}</span>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
           <el-table-column label="Nombre" width="250">
              <template slot-scope="data">
                <span>{{ data.row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Impuestos" width="100">
              <template slot-scope="data">
                <div :key="impuesto.title"  v-for="impuesto in data.row.tax_lines">
                  {{ impuesto.price }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Precio" width="100">
              <template slot-scope="data">
                <span>{{ data.row.price }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Descuentos" width="100">
              <template slot-scope="data">
                <div :key="descuento.title"  v-for="descuento in data.row.discount_allocations">
                  {{ descuento.amount / data.row.quantity }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Precio Final" width="100">
              <template slot-scope="data">
                <span>{{ data.row.price_final }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Cantidad" width="100">
              <template slot-scope="data">
                <span>{{ data.row.quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Descripción" width="300">
              <template slot-scope="data">
                <span>{{ data.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Vendedor" width="250">
              <template slot-scope="data">
                <span>{{ data.row.vendor }}</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- Fin tabla productos -->
        </template>
      </el-table-column>
      <!-- <el-table-column label="# Orden" fixed width="80">
        <div slot-scope="scope" >
          {{ scope.row.order_number }}
        </div>
      </el-table-column> -->
      <el-table-column label="ID Shopify" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.id_order_sh }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Sucursal" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.location }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Descripción" width="110" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.orderDescription }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Incluye impuesto"  width="120" align="center" >
        <template slot-scope="scope">
          {{ scope.row.taxes_included }}
        </template>
      </el-table-column>
      <el-table-column label="Total productos"  width="120" align="center" >
        <template slot-scope="scope">
          {{ scope.row.total_line_items_price }}
        </template>
      </el-table-column>
      <el-table-column label="Descuentos"   align="center" >
        <el-table-column label="Motivo"  width="160" align="center" >
          <template slot-scope="scope">
            <div :key="discount.code"  v-for="discount in scope.row.discount_codes">
              {{ discount.code }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Monto"  width="100" align="center" >
          <template slot-scope="scope">
            <div :key="discount.code"  v-for="discount in scope.row.discount_codes">
              {{ discount.amount }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Total descuentos"  width="120" align="center" >
        <template slot-scope="scope">
          {{ scope.row.total_discounts }}
        </template>
      </el-table-column>
      <el-table-column label="Subtotal"  width="120" align="center" >
        <template slot-scope="scope">
          {{ scope.row.subtotal_price }}
        </template>
      </el-table-column>
      <el-table-column label="Antes de Impuestos"  width="120" align="center" >
        <template slot-scope="scope">
          {{ scope.row.beforeTaxAmount }}
        </template>
      </el-table-column>
      <el-table-column label="Impuestos" width="120" align="center">
        <template slot-scope="scope">
         <span >{{ scope.row.taxAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Precio Total" width="120" align="center">
        <template slot-scope="scope" >
         <span>{{ scope.row.total_price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Medio de pago" width="120" align="center">
        <template slot-scope="scope" >
         <span>{{ scope.row.gateway }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha registro SH" width="110" align="center">
        <template slot-scope="scope" >
         <span>{{ scope.row.created_at_sh | formatDateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Cliente" align="center">
        <el-table-column label="ID" width="100" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.customer">
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">{{scope.row.customer.id}}</div>
                <span>{{ scope.row.customer.id.toString().substr(-4) }}</span>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Correo" width="140" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.customer">
              <span>{{scope.row.customer.email}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Nombre" width="150" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.customer">
              <div v-if="scope.row.customer.first_name == null">
                <span>{{scope.row.customer.last_name}}</span>
              </div>
              <div v-else-if="scope.row.customer.last_name == null">
                <span>{{scope.row.customer.first_name}}</span>
              </div>
              <div v-else>
                <span>{{scope.row.customer.last_name+" "+scope.row.customer.first_name}}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Teléfono" width="140" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.customer">
              <span>{{scope.row.customer.phone}}</span>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Fecha registro" width="120" align="center">
        <template slot-scope="scope" >
         <span>{{ scope.row.created_at | formatDateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Error"  width="170" align="center" >
        <template slot-scope="scope">
          {{ scope.row.error }}
        </template>
      </el-table-column>
      <el-table-column label="Operaciones" fixed="right" width="170" align="center" >
        <template slot-scope="scope">
          <div v-if="scope.row.status == 'Invoiced'">
            <el-button size="mini" type="success" :loading="scope.row.loadingBtnDownloadPDF" @click.stop.prevent="handleDownloadPDF(scope.row)" round>PDF</el-button>
            <el-button size="mini" type="success" :loading="scope.row.loadingBtnDownloadXML" @click.stop.prevent="handleDownloadXML(scope.row)" round>XML</el-button>
          </div>
          <div v-else-if="scope.row.status == 'Error'">
            <el-button size="mini" type="danger" :loading="scope.row.loadingBtnInvoice" @click.stop.prevent="handleFix(scope.row)" round>Corregir</el-button>
          </div>
          <div v-else-if="scope.row.status == 'Sent to invoicing'">
            <el-button size="mini" type="warning" :loading="scope.row.loadingBtnInvoice" @click.stop.prevent="handleVerify(scope.row)" round>Verificar facturación</el-button>
          </div>
          <div v-else>
            <el-button size="mini" type="primary" :loading="scope.row.loadingBtnInvoice" @click.stop.prevent="handleInvoice(scope.row)" round>Enviar a facturación</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
    <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
      :total="parseInt(totalPages)">
    </el-pagination>
  </el-row>
  </div>
</template>

<script>
import { search, getPage, sendToInvoice, getLocations, getStatus, verifyInvoice, downloadPDF, downloadXML } from '@/api/shopify'

export default {
  data () {
    return {
      filterForm: {
        query: '',
        filterLocation: '',
        filterStatus: '',
        filterDateRegisterSHStartRange: '',
        filterDateRegisterSHEndRange: '',
        filterDateRegisterStartRange: '',
        filterDateRegisterEndRange: ''
      },
      locations: [],
      status: [],
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      idUserTags: [],
      filterDateRegisterSHRange: '',
      filterDateRegisterRange: '',
      totalPages: '',
      totalItemPage: '',
      currentPage: '',
      list: null,
      loading: false,
      sizePerPage: 25
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async goChart () {
      this.$router.push({ name: 'dashboard' })
    },
    async handleCurrentChange (page) {
      // console.log('current')
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.filterForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      // console.log('change')
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.filterForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.filterForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async getLocationsShopify () {
      await getLocations()
        .then((response) => {
          this.locations = response.data
        })
        .catch(this.responseCatch)
    },
    async getStatusShopify () {
      await getStatus()
        .then((response) => {
          this.status = response.data
          // console.log(response)
        })
        .catch(this.responseCatch)
    },
    fetchData () {
      this.handleSearchFilter()
      this.getLocationsShopify()
      this.getStatusShopify()
    },
    handleFilterDateRegisterSH () {
      if (this.filterDateRegisterSHRange) {
        this.filterForm.filterDateRegisterSHStartRange = this.filterDateRegisterSHRange[0]
        this.filterForm.filterDateRegisterSHEndRange = this.filterDateRegisterSHRange[1]
        this.handleSearchFilter()
      } else {
        this.filterForm.filterDateRegisterSHStartRange = ''
        this.filterForm.filterDateRegisterSHEndRange = ''
        this.handleSearchFilter()
      }
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.filterForm.filterDateRegisterStartRange = this.filterDateRegisterRange[0]
        this.filterForm.filterDateRegisterEndRange = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.filterForm.filterDateRegisterStartRange = ''
        this.filterForm.filterDateRegisterEndRange = ''
        this.handleSearchFilter()
      }
    },
    handleClean () {
      this.filterDateRegisterRange = []
      this.filterDateRegisterSHRange = []
      this.filterForm.query = ''
      this.filterForm.filterLocation = ''
      this.filterForm.filterStatus = ''
      this.filterForm.filterDateRegisterSHStartRange = ''
      this.filterForm.filterDateRegisterSHEndRange = ''
      this.filterForm.filterDateRegisterStartRange = ''
      this.filterForm.filterDateRegisterEndRange = ''
      this.handleSearchFilter()
    },
    async handleInvoice (row) {
      this.$set(row, 'loadingBtnInvoice', true)
      await sendToInvoice(row._id)
        .then((response) => {
          if (response.data === true) {
            this.$message({
              showClose: true,
              message: 'Transacción enviada a facturar exitosamente',
              type: 'success'
            })
          } else {
            this.$message.error('Oops, algo a salido mal.')
          }
        })
        .catch(this.responseCatch, this.reload())
        .finally(() => (this.$set(row, 'loadingBtnInvoice', false)))
    },
    reload () {
      setTimeout(() => { this.handleCurrentChange(this.currentPage) }, 1000)
    },
    async handleVerify (row) {
      this.$set(row, 'loadingBtnInvoice', true)
      await verifyInvoice(row._id)
        .then((response) => {
          if (response.data === false) {
            this.$message({
              showClose: true,
              message: 'Ticket todavia no ha sido facturado',
              type: 'warning'
            })
          } else {
            this.$message({
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: '<strong>Ticket facturado.</strong><br>Folio Fiscal:' + response.data.folio_fiscal,
              type: 'success'
            })
            row.status = 'Invoiced'
            row.folio_fiscal = response.data.folio_fiscal
            row.folio = response.data.folio
          }
        })
        .catch(this.responseCatch)
        .finally(() => (this.$set(row, 'loadingBtnInvoice', false)))
    },
    async handleDownloadPDF (row) {
      this.$set(row, 'loadingBtnDownloadPDF', true)
      await downloadPDF(row._id)
        .then((response) => {
          console.log(response)
          const link = document.createElement('a')
          link.href = response.data.file
          link.download = response.data.name
          link.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => (this.$set(row, 'loadingBtnDownloadPDF', false)))
    },
    async handleDownloadXML (row) {
      this.$set(row, 'loadingBtnDownloadXML', true)
      await downloadXML(row._id)
        .then((response) => {
          console.log(response)
          const link = document.createElement('a')
          link.href = response.data.file
          link.download = response.data.name
          link.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => (this.$set(row, 'loadingBtnDownloadXML', false)))
    },
    handleFix (row) {
      this.$router.push({ name: 'shopify.product.fix', query: { idP: row.productTofix, idT: row._id } })
    }
  }
}
</script>
